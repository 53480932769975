<template>
  <div class="page-wrapper">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="自定义发送" name="first">
        <el-form
          ref="taskFormSingle"
          :model="taskFormSingle"
          label-width="120px"
          :rules="rulesSingle"
        >
          <el-form-item label="任务名称" prop="taskName">
            <el-input
              v-model="taskFormSingle.taskName"
              placeholder="请输入任务名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="短信内容" prop="content">
            <el-input
              v-model="taskFormSingle.content"
              placeholder="请输入短信内容"
              type="textarea"
              rows="5"
              @input="handleCounts"
            ></el-input>
            <div>
              <span>
                当前字数：
                <span style="color: red">{{ taskFormSingle.words }}</span>
                个
              </span>
              <span style="margin-left: 20px">
                计费条数：
                <span style="color: red">{{ taskFormSingle.bar }}</span>
                条
              </span>
            </div>
          </el-form-item>
          <!-- <el-form-item label="短信模板" prop="temId">
            <el-select
              v-model="taskForm.temId"
              placeholder="请选择任务模板"
              style="width: 100%"
              @change="handleChange"
            >
              <el-option
                v-for="(item, i) in dataList"
                :key="i"
                :label="item.temName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item> -->
          <el-form-item label="发送对象">
            <div class="file_box">
              <el-upload
                ref="upload"
                class="upload-demo"
                action=""
                accept=".xls, .xlsx"
                :limit="1"
                :on-remove="removeExcel"
                :http-request="uploadExcel"
              >
                <el-button color="#409eff" plain class="upload_box">
                  <el-icon class="el-icon--right"><Upload /></el-icon>
                  <span style="margin-left: 5px">文件上传</span>
                </el-button>
              </el-upload>
              <a
                class="download_box"
                download
                href="http://sms.mmaicc.com/importTemp.xlsx"
              >
                <el-button color="#626aef" plain>
                  <el-icon class="el-icon--right"><Download /></el-icon>
                  <span style="margin-left: 5px">模板下载</span>
                </el-button>
              </a>
            </div>
          </el-form-item>
          <el-form-item class="submit_box">
            <el-button type="primary" @click="onSubmitSingle('taskFormSingle')"
              >发送短信</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
      <el-tab-pane label="模板发送" name="second">
        <el-form
          ref="taskForm"
          :model="taskForm"
          label-width="120px"
          :rules="rules"
        >
          <el-form-item label="任务名称" prop="taskName">
            <el-input
              v-model="taskForm.taskName"
              placeholder="请输入任务名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="短信模板" prop="temId">
            <el-select
              v-model="taskForm.temId"
              placeholder="请选择任务模板"
              style="width: 100%"
              @change="handleChange"
            >
              <el-option
                v-for="(item, i) in dataList"
                :key="i"
                :label="item.temName"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="发送对象">
            <div class="file_box">
              <el-upload
                ref="upload"
                class="upload-demo"
                action=""
                accept=".xls, .xlsx"
                :limit="1"
                :on-remove="removeExcel"
                :http-request="uploadExcel"
              >
                <el-button color="#409eff" plain class="upload_box">
                  <el-icon class="el-icon--right"><Upload /></el-icon>
                  <span style="margin-left: 5px">文件上传</span>
                </el-button>
              </el-upload>
              <a
                class="download_box"
                download
                href="http://sms.mmaicc.com/importTemp.xlsx"
              >
                <el-button color="#626aef" plain>
                  <el-icon class="el-icon--right"><Download /></el-icon>
                  <span style="margin-left: 5px">模板下载</span>
                </el-button>
              </a>
            </div>
          </el-form-item>
          <el-form-item class="submit_box">
            <el-button type="primary" @click="onSubmit('taskForm')"
              >发送短信</el-button
            >
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import axios from "axios";
import { Upload, Download } from "@element-plus/icons-vue";
import { showLoading, hideLoading } from "../../utils/loading";
export default {
  name: "messageAdd",
  components: { Upload, Download },
  data() {
    return {
      activeName: "first",
      // taskId: "",
      dataList: [],
      taskForm: {
        taskName: "",
        temId: "",
        temName: "",
      },
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        temId: [{ required: true, message: "请选择短信模板", trigger: "blur" }],
      },
      uploadForm: "",
      // 自定义
      taskFormSingle: {
        taskName: "",
        content: "",
        words: 0, //字数
        bar: 0, //条数
      },
      rulesSingle: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入短信内容", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    this.getDataList();
    // this.getTaskId();
  },
  methods: {
    handleCounts(val) {
      let nums = val.length;
      this.taskFormSingle.words = nums;
      // 70个字以内算1条短信。 134以内算2条，201以内计3条。 我们现在是205个一条短信计4条
      let count = 0;
      if (nums <= 70) {
        count = 1;
      } else if (nums > 70 && nums <= 134) {
        count = 2;
      } else if (nums > 134 && nums <= 201) {
        count = 3;
      } else if (nums > 201) {
        count = 4;
      }
      this.taskFormSingle.bar = count;
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    getTaskId() {
      this.$http
        .request("getTaskId", {})
        .then((res) => {
          this.taskId = res.result;
        })
        .catch(() => {});
    },
    // 获取列表数据-模板选择列表
    getDataList() {
      this.$http
        .request("getTemplateList", {
          temStatus: 2,
        })
        .then((res) => {
          this.dataList = res.result.data;
        })
        .catch(() => {});
    },
    // 上传
    uploadExcel(file) {
      let form = new FormData();
      form.append("file", file.file);
      // form.append("id", this.taskId);
      this.uploadForm = form;
      this.uploader = file;
    },
    removeExcel() {
      this.uploadForm = "";
    },
    handleChange(val) {
      this.dataList.forEach((item) => {
        if (item.id == val) {
          this.taskForm.temName = item.temName;
        }
      });
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.uploadForm) {
            showLoading("文件上传中，请稍等...");
            this.$http
              .request("upload", this.uploadForm)
              .then((res) => {
                let taskId = res.result;
                hideLoading();
                showLoading("任务创建中，请稍等...");
                this.uploader.onSuccess();
                console.log(res, "5555555555555");
                // 文件上传成功后添加任务
                this.$http
                  .request("addTask", {
                    id: taskId,
                    ...this.taskForm,
                  })
                  .then((res) => {
                    hideLoading();
                    this.$message.success("操作成功");
                    this.$router.push("messageTaskList");
                  })
                  .catch(() => {});
              })
              .catch(() => {
                this.$message.error("文件上传失败，请重新上传文件");
              });
          } else {
            this.$message.error("请先上传文件！");
          }
        }
      });
    },
    onSubmitSingle(formName) {
      console.log(this.taskFormSingle, "0000000000");
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.uploadForm) {
            showLoading("文件上传中，请稍等...");
            this.$http
              .request("upload", this.uploadForm)
              .then((res) => {
                let taskId = res.result;
                hideLoading();
                showLoading("任务创建中，请稍等...");
                this.uploader.onSuccess();
                console.log(res, "5555555555555");
                console.log(this.taskFormSingle, "66666666666");
                // 文件上传成功后添加任务
                this.$http
                  .request("addCustTask", {
                    id: taskId,
                    ...this.taskFormSingle,
                  })
                  .then((res) => {
                    hideLoading();
                    this.$message.success("操作成功");
                    this.$router.push("messageTaskList");
                  })
                  .catch(() => {});
              })
              .catch(() => {
                this.$message.error("文件上传失败，请重新上传文件");
              });
          } else {
            this.$message.error("请先上传文件！");
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  width: 50%;
}
.upload_box {
  // color: #409eff;
  // background: #ecf5ff;
  // border-color: #b3d8ff;
  margin-bottom: 10px;
}
.file_box {
  position: relative;
}
.download_box {
  margin-bottom: 10px;
  margin-left: 10px;
  position: absolute;
  top: 0;
  left: 120px;
  text-decoration: none;
}
.submit_box {
  .el-button {
    width: 200px;
  }
}
.el-form-item {
  margin-top: 30px;
}
</style>


